<template>
  <div class="basicInfo">
    <el-form
      v-if="isShow"
      :model="basicInfo"
      :rules="rulesBasicInfo"
      ref="rulesBasicInfo"
      label-width="120px"
    >
      <el-form-item label="项目名称：" prop="projectName">
        <el-input v-model="basicInfo.projectName" class="lager-input" />
      </el-form-item>
      <el-form-item label="所在地区：" prop="projectCountry">
        <cascader-area
          :country="basicInfo.projectCountry"
          :province="basicInfo.projectProvince"
          :city="basicInfo.projectCity"
          @searchCountry="searchCountry"
          @searchProvince="searchProvince"
          @searchCity="searchCity"
        />
      </el-form-item>
      <el-form-item label="项目简介：">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 20 }"
          v-model="basicInfo.projectIntro"
          style="width:80%"
        />
      </el-form-item>
      <el-form-item label="发布时间：">
        <data-time
          @dateChange="releaseTime"
          :parentYear="Number(basicInfo.yearsTime)"
          :parentMonth="Number(basicInfo.monthTime)"
          :parentDay="Number(basicInfo.dayTime)"
        />
      </el-form-item>
      <el-form-item label="招标预算：">
        <el-input v-model="basicInfo.projectBudget" class="lager-input" />
      </el-form-item>
      <el-form-item label="招标单位：" prop="projectCompany">
        <el-input v-model="basicInfo.projectCompany" class="lager-input" />
      </el-form-item>
      <el-form-item label="项目阶段：">
        <dic-radio-group :code.sync="basicInfo.projectStage" type-code="012" />
      </el-form-item>
      <el-form-item label="开标时间：" prop="projectCreatetime">
        <data-time
          :parentYear="Number(basicInfo.yearsCreatetime)"
          :parentMonth="Number(basicInfo.monthCreatetime)"
          :parentDay="Number(basicInfo.dayCreatetime)"
          @dateChange="tenderStartTime"
        />
      </el-form-item>
      <el-form-item label="中标金额：" prop="projectMoney">
        <el-input v-model="basicInfo.projectMoney" class="lager-input" />
      </el-form-item>
      <el-form-item label="标书获取时间：">
        <data-time
          :parentYear="Number(basicInfo.years)"
          :parentMonth="Number(basicInfo.month)"
          :parentDay="Number(basicInfo.day)"
          @dateChange="tenderGetTime"
        />
      </el-form-item>
      <el-form-item label="标书截止时间：">
        <data-time
          :parentYear="Number(basicInfo.yearsDeadline)"
          :parentMonth="Number(basicInfo.monthDeadline)"
          :parentDay="Number(basicInfo.dayDeadline)"
          @dateChange="tenderEndTime"
        />
      </el-form-item>
      <el-form-item label="投标截止时间：">
        <data-time
          :parentYear="Number(basicInfo.projectYears)"
          :parentMonth="Number(basicInfo.projectMonth)"
          :parentDay="Number(basicInfo.projectDay)"
          @dateChange="castEndTime"
        />
      </el-form-item>
      <el-form-item label="投标方式：" prop="projectMode">
        <!-- <dic-radio-group :code.sync="basicInfo.projectMode" type-code="012" /> -->
        <el-radio-group v-model="basicInfo.projectMode">
          <el-radio
            v-for="(item, index) in query.projectModeArray"
            :key="index"
            :label="item.label"
          ></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="电子招标：">
        <el-radio-group v-model="basicInfo.projectElmode">
          <el-radio
            v-for="(item, index) in query.judgeArray"
            :key="index"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="行业类别：" prop="projectIndustryType">
        <dic-checkbox-group
          :code.sync="basicInfo.projectIndustryType"
          tag="行业"
          @updateCode="updateCode"
        />
      </el-form-item>
      <el-form-item label="领域类别：" prop="projectFieldType">
        <dic-checkbox-group
          :code.sync="basicInfo.projectFieldType"
          tag="领域"
          @updateCode="updateCode"
        />
      </el-form-item>
      <el-form-item label="领域标签：" v-if="projectDataType">
        <el-input
          v-model="basicInfo.wisdomTag"
          style="width:80%"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="行业标签：" v-if="projectDataType">
        <el-input
          v-model="basicInfo.IndustryTag"
          style="width:80%"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="关键词：" v-if="projectDataType">
        <el-input
          v-model="basicInfo.antistop"
          style="width: 80%"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <!-- <div class="bottom">
        <el-button type="primary" @click="saveItem">保存</el-button>
        <el-button @click="cancelItem">取消</el-button>
      </div>-->
    </el-form>
  </div>
</template>

<script>
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import CascaderArea from "@/components/CascaderArea.vue";
import DataTime from "@/components/DataTime.vue";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import { time } from "../mixins/time";
import {
  addProjectData,
  getProjectDataById,
  updateProjectData
} from "@/api/numberProject";
export default {
  name: "BasicInfo",

  mixins: [time],

  props: {
    projectDataType: {
      type: Number
    },
    id: {
      type: Number
    },
    basicInfo: {
      type: Object
    }
  },

  components: {
    DicCheckboxGroup,
    CascaderArea,
    DataTime,
    DicRadioGroup
  },

  data() {
    return {
      rulesBasicInfo: {
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "blur" }
        ],
        projectCountry: [
          { required: true, message: "请选择所在地区", trigger: "blur" }
        ],
        projectCompany: [
          { required: true, message: "请输入招标单位", trigger: "blur" }
        ],
        projectStage: [
          { required: true, message: "请选择项目阶段", trigger: "change" }
        ],
        projectMode: [
          { required: true, message: "请选择电子招标", trigger: "change" }
        ]
        // projectIndustryType: [
        //   { required: true, message: '请选择行业类别', trigger: 'change' },
        // ],
        // projectFieldType: [
        //   { required: true, message: '请选择领域类别', trigger: 'change' },
        // ],
      },
      query: {
        judgeArray: [
          { label: "是", value: "1" },
          { label: "否", value: "0" }
        ],
        projectModeArray: [
          {
            label: "公开招标"
          },
          {
            label: "竞争性谈判或竞争性磋商"
          },
          {
            label: "单一来源采购"
          },
          {
            label: "询价"
          },
          {
            label: "其他"
          }
        ]
      },
      isShow: false
    };
  },
  created() {
    // 编辑
    setTimeout(() => {
      if (this.projectDataType && this.basicInfo.id) {
        this.isShow = true;
      } else if (!this.projectDataType) {
        this.isShow = true;
      }
    }, 400);
  },

  methods: {
    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.basicInfo.fieldChildrenId = val;
          break;

        case "行业":
          this.basicInfo.industryChildrenId = val;
          break;

        default:
          break;
      }
    },
    searchCountry(val) {
      this.basicInfo.projectCountry = val;
    },
    searchProvince(val) {
      this.basicInfo.projectProvince = val;
    },
    searchCity(val) {
      this.basicInfo.projectCity = val;
    }
  }
};
</script>

<style></style>
