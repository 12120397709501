<template>
  <div class="demandInfo">
    <el-form :model="demandInfo" :rules="rulesDemandInfo" ref="rulesDemandInfo" label-width="130px">
      <el-form-item label="需求机构名称：" prop="institutionName">
        <el-input v-model="demandInfo.institutionName" class="lager-input" />
      </el-form-item>
      <el-form-item label="所在地区：">
        <cascader-area
          :country="demandInfo.demandCountry"
          :province="demandInfo.demandProvince"
          :city="demandInfo.demandCity"
          @searchCountry="searchCountry"
          @searchProvince="searchProvince"
          @searchCity="searchCity"
        />
      </el-form-item>
      <el-form-item label="需求机构类型：" prop="demandType">
        <dic-checkbox-group :code.sync="demandInfo.demandType" type-code="010" ref="demandType" />
      </el-form-item>
      <el-form-item label="联系人：" prop="linkman">
        <el-input v-model="demandInfo.linkman" class="lager-input" />
      </el-form-item>
      <el-form-item label="联系电话：" prop="companyLinkman">
        <el-input v-model="demandInfo.companyLinkman" class="lager-input" />
      </el-form-item>
      <el-form-item label="联系邮箱：">
        <el-input v-model="demandInfo.companyLinkEmail" class="lager-input" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import DicCheckboxGroup from '@/components/DicCheckboxGroup.vue'
import CascaderArea from '@/components/CascaderArea.vue'
export default {
  name: 'DemandInfo',

  props: {
    demandInfo: {
      type: Object,
    },
  },

  components: {
    DicCheckboxGroup,
    CascaderArea,
  },

  data() {
    return {
      rulesDemandInfo: {
        institutionName: [
          { required: true, message: '请输入需求机构名称', trigger: 'blur' },
        ],
        demandType: [
          { required: true, message: '请选择需求机构类型', trigger: 'blur' },
        ],
        // linkman: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        // companyLinkman: [
        //   { required: true, message: '请输入联系电话', trigger: 'blur' },
        // ],
      },
    }
  },

  mounted() {
    setTimeout(() => {
      if (this.demandInfo.demandType.length && this.$refs.demandType) {
        this.$refs.demandType.getDicData()
      }
    }, 500)
  },

  methods: {
    searchCountry(val) {
      this.demandInfo.demandCountry = val
    },
    searchProvince(val) {
      this.demandInfo.demandProvince = val
    },
    searchCity(val) {
      this.demandInfo.demandCity = val
    },
  },
}
</script>

<style>
</style>
