export const time = {
  methods: {
    // 发布时间
    releaseTime(year, month, day) {
      this.basicInfo.yearsTime = year
      this.basicInfo.monthTime = month
      this.basicInfo.dayTime = day
    },
    // 开标时间
    tenderStartTime(year, month, day) {
      this.basicInfo.yearsCreatetime = year
      this.basicInfo.monthCreatetime = month
      this.basicInfo.dayCreatetime = day
    },
    // 标书获取时间
    tenderGetTime(year, month, day) {
      this.basicInfo.years = year
      this.basicInfo.month = month
      this.basicInfo.day = day
    },
    // 标书截止时间
    tenderEndTime(year, month, day) {
      this.basicInfo.yearsDeadline = year
      this.basicInfo.monthDeadline = month
      this.basicInfo.dayDeadline = day
    },
    // 投标截止时间
    castEndTime(year, month, day) {
      this.basicInfo.projectYears = year
      this.basicInfo.projectMonth = month
      this.basicInfo.projectDay = day
    },
  }
}
