<template>
  <div class="acessoryInfo">
    <el-button type="primary" icon="jr-iconjr-icon-add" @click="dialogVisible=true;dialogType=0">添加</el-button>
    <el-button @click="multiDelete">
      批量删除
      <i class="jr-iconjr-icon-delect" />
    </el-button>
    <el-table
      class="ly_table"
      :data="acessoryInfoTable"
      @selection-change="handleSelectionChange"
      :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
    >
      <el-table-column type="selection" width="55" align="center" />
      <template slot="empty">
          <img
            src="@/assets/img/table.png"
            alt="没有数据"
            srcset=""
          />
        </template>
      <el-table-column prop="projectFileName" label="附件名称" align="center" />
      <el-table-column prop="projectFileType" label="附件类型" align="center" />
      <el-table-column prop="projectFileIntro" label="附件简介" align="center" />
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <button-table
            @buttonClick="downItem(scope.$index,scope.row)"
            content="下载"
            icon="jr-iconjr-icon-download"
          />
          <button-table
            @buttonClick="seeItem(scope.$index,scope.row)"
            content="查看"
            icon="jr-iconjr-icon-eye"
          />
          <button-table
            @buttonClick="editItem(scope.$index,scope.row)"
            content="编辑"
            icon="jr-iconjr-icon-edit"
          />
          <button-table
            @buttonClick="deleteItem(scope.$index,scope.row)"
            content="删除"
            icon="jr-iconjr-icon-delect"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="dialogType ? '编辑附件' : '添加附件'"
      :visible.sync="dialogVisible"
      width="40%"
      @close="handleClose"
      center
    >
      <el-form
        ref="acessoryInfoObj"
        :model="acessoryInfoObj"
        :rules="acessoryInfoRules"
        label-width="100px"
      >
        <el-form-item label="附件上传：">
          <upload-file
            :saveUrl.sync="acessoryInfoObj.projectFileKey"
            :lookUrl.sync="acessoryInfoObj.url"
            ref="uploadFile"
          />
        </el-form-item>
        <el-form-item label="附件类型：">
          <el-radio-group v-model="acessoryInfoObj.projectFileType">
            <el-radio v-for="(item,index) in query.fileType" :key="index" :label="item.label"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="附件简介：">
          <el-input
            v-model="acessoryInfoObj.projectFileIntro"
            placeholder="请输入附件简介"
            type="textarea"
            :autosize="{ minRows: 2}"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitForm">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ButtonTable from '@/components/ButtonTable.vue'
import UploadFile from '@/components/UploadFile.vue'
import {
  addProjectFile,
  updateProjectFile,
  deleteProjectFile,
  getProjectFileById,
} from '@/api/numberProject'
import { downFile } from '@/utils/tool'
export default {
  name: 'AcessoryInfo',

  props: {
    projectDataType: {
      type: Number,
    },
    acessoryInfoTable: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
    },
  },

  components: {
    UploadFile,
    ButtonTable,
  },

  data() {
    return {
      dialogType: 0, // 面板类型 0 添加 1 编辑
      dialogVisible: false,
      acessoryInfoObj: {
        projectFileKey: '', // 保存的附件url
        projectFileName: '', // 文件名
        url: '', // 展示的附件url
        projectFileType: '', // 附件类型
        projectFileIntro: '', // 附件简介
        timestamp: new Date().getTime(), // 当前时间戳
      },
      currentIndex: '',
      // 验证
      acessoryInfoRules: {},
      selectionVal: [], // 表格多选选择值
      delete: {
        id: [],
        multiIds: [],
      },
      query: {
        fileType: [
          {
            label: '合同文件',
          },
          {
            label: '标书',
          },
          {
            label: '需求文件',
          },
          {
            label: '其他',
          },
        ],
      },
    }
  },

  methods: {
    // 下载
    downItem(index, row) {
      downFile(
        { urlStr: row.projectFileKey },
        '/projectFile/downloadUrlFile',
        'application/pdf',
        row.projectFileName
      )
    },
    // 在线查看
    seeItem(index, row) {
      window.open(row.url)
      // if (this.projectDataType) {
      //   this.search(row.id)
      //         if (this.acessoryInfoObj.url) {
      //   window.open(this.acessoryInfoObj.url)
      // }
      // } else {
      //   Object.assign(this.acessoryInfoObj, row)
      // }
    },
    async search(params) {
      const { data: res } = await getProjectFileById({ id: params })

      this.acessoryInfoObj = res
    },
    // 编辑
    editItem(index, row) {
      this.currentIndex = index
      if (this.projectDataType) {
        this.search(row.id)
      } else {
        Object.assign(this.acessoryInfoObj, row)
      }

      this.dialogType = 1

      this.dialogVisible = true
    },
    // 删除单个
    async deleteItem(index, row) {
      this.$confirm('确认删除吗 ?', '提示')
        .then(async () => {
          // 编辑
          if (this.projectDataType) {
            this.delete.id.push(row.id)

            this._deleteAcessory(this.delete.id)
          } else {
            this.$emit('deleteAcessoryInfo', index)
          }
        })
        .catch(() => {})
    },

    async _deleteAcessory(params) {
      const res = await deleteProjectFile(params)

      if (res.code === 200) {
        this.$message.success('删除成功')
        this.$emit('searchAcessoryInfo')
      }
    },

    handleSelectionChange(val) {
      this.selectionVal = val
      this.delete.multiIds = val.map((item) => {
        return item.id
      })
    },
    // 批量删除
    multiDelete() {
      this.$confirm('确认删除吗 ?', '提示')
        .then(async () => {
          // 编辑
          if (this.projectDataType) {
            if (this.delete.multiIds.length) {
              this._deleteAcessory(this.delete.multiIds)
            }
          } else {
            this.$emit('deleteMultiAcessoryInfo', this.selectionVal)
          }
        })
        .catch(() => {})
    },
    handleClose() {
      this.dialogVisible = false

      this.acessoryInfoObj = {
        projectFileKey: '', // 保存的附件url
        url: '', // 展示的附件url
        projectFileType: '', // 附件类型
        projectFileIntro: '', // 附件简介
        timestamp: new Date().getTime(), // 当前时间戳
      }

      this.$refs.uploadFile.clear()
    },
    submitForm() {
      this.$refs.acessoryInfoObj.validate(async (valid) => {
        if (valid) {
          let res
          // 项目数据编辑
          if (this.projectDataType) {
            // 附件信息编辑
            if (this.dialogType) {
              // 编辑
              res = await updateProjectFile(this.acessoryInfoObj)
            } else {
              // 新增
              res = await addProjectFile(
                Object.assign(this.acessoryInfoObj, { projectId: this.id })
              )
            }
            if (res.code === 200) {
              this.$message.success(`${this.dialogType ? '编辑' : '新增'}成功`)

              this.$emit('searchAcessoryInfo')
            }
          } else {
            // 项目数据新增
            // 附件信息编辑
            this.acessoryInfoObj.projectFileName =
              this.acessoryInfoObj.projectFileKey.substring(
                this.acessoryInfoObj.projectFileKey.indexOf('/') + 1
              )
            if (this.dialogType) {
              this.$emit(
                'updateAcessoryInfo',
                this.currentIndex,
                this.acessoryInfoObj
              )
            } else {
              this.$emit('addAcessoryInfo', this.acessoryInfoObj)
            }
          }
        }
      })

      this.dialogVisible = false
    },
  },
}
</script>

<style>
</style>
