<template>
  <div class="projectDataInfo">
    <el-tabs v-model="activeName" class="ly_tabs" @tab-click="handleClick">
      <el-tab-pane label="项目基本信息" name="first">
        <basic-info
          :projectDataType="projectDataType"
          ref="basicInfo"
          :basicInfo.sync="basicInfo"
          :id="id"
        />
        <extra-info
          v-if="isShowExtra && projectDataType"
          :creator="basicInfo.adminName"
          :modifier="basicInfo.changeName"
          :updateTime="basicInfo.changeTime"
          :createTime="basicInfo.createTime"
        />
      </el-tab-pane>
      <el-tab-pane label="需求机构信息" name="second">
        <demand-info :demandInfo.sync="demandInfo" ref="demandInfo" />
        <extra-info
          v-if="projectDataType"
          :creator="basicInfo.adminName"
          :modifier="basicInfo.changeName"
          :updateTime="basicInfo.changeTime"
          :createTime="basicInfo.createTime"
        />
      </el-tab-pane>
      <el-tab-pane label="供应商信息" name="third">
        <supplier-info
          :supplierInfoTable.sync="supplierInfoTable"
          :projectDataType="projectDataType"
          :id="id"
          @addSupplierInfo="addSupplierInfo"
          @updateSupplierInfo="updateSupplierInfo"
          @deleteSupplierInfo="deleteSupplierInfo"
          @searchSupplierInfo="searchSupplierInfo"
        />
        <extra-info
          v-if="projectDataType"
          :creator="basicInfo.adminName"
          :modifier="basicInfo.changeName"
          :updateTime="basicInfo.changeTime"
          :createTime="basicInfo.createTime"
        />
      </el-tab-pane>
      <el-tab-pane label="附件信息" name="fourth">
        <acessory-info
          :projectDataType="projectDataType"
          :acessoryInfoTable.sync="acessoryInfoTable"
          :id="id"
          @addAcessoryInfo="addAcessoryInfo"
          @updateAcessoryInfo="updateAcessoryInfo"
          @deleteAcessoryInfo="deleteAcessoryInfo"
          @searchAcessoryInfo="searchAcessoryInfo"
          @deleteMultiAcessoryInfo="deleteMultiAcessoryInfo"
        />
        <extra-info
          v-if="projectDataType"
          :creator="basicInfo.adminName"
          :modifier="basicInfo.changeName"
          :updateTime="basicInfo.changeTime"
          :createTime="basicInfo.createTime"
        />
      </el-tab-pane>
    </el-tabs>
    <div class="bottom">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>

<script>
import {
  addProjectData,
  getProjectDataById,
  updateProjectData,
  getProjectDemandById,
  updateProjectDemand,
  getProjectSupplier,
  getProjectFile
} from "@/api/numberProject";
import BasicInfo from "./BasicInfo.vue";
import DemandInfo from "./DemandInfo.vue";
import AcessoryInfo from "./AcessoryInfo.vue";
import SupplierInfo from "./SupplierInfo.vue";
import ExtraInfo from "@/components/ExtraInfo.vue";
export default {
  name: "ProjectDataInfo",

  components: {
    BasicInfo,
    DemandInfo,
    AcessoryInfo,
    SupplierInfo,
    ExtraInfo
  },

  data() {
    return {
      activeName: "first",
      isShowExtra: false,
      // 基本信息
      basicInfo: {
        projectName: "", //项目名称
        projectCountry: "", // 国家
        projectProvince: "", // 省份
        projectCity: "", // 地区
        projectIntro: "", // 项目简介
        yearsTime: null, // 发布时间年
        monthTime: null, // 发布时间月
        dayTime: null, // 发布时间日
        projectBudget: "", // 招标预算
        projectCompany: "", // 招标单位
        projectStage: "", // 项目阶段
        yearsCreatetime: null, // 开标时间年
        monthCreatetime: null, // 开标时间月
        dayCreatetime: null, // 开标时间日
        projectMoney: null, // 中标金额
        years: null, // 标书获取时间年
        month: null, // 标书获取时间月
        day: null, // 标书获取时间日
        yearsDeadline: null, // 标书截止时间年
        monthDeadline: null, // 标书截止时间月
        dayDeadline: null, //标书截止时间日
        projectYears: null, // 投标截止时间年
        projectMonth: null, // 投标截止时间月
        projectDay: null, // 投标截止时间日
        projectMode: "", //投标方式
        projectElmode: "", // 电子招标
        projectIndustryType: [], // 行业类别
        industryChildrenId: [], // 行业类别子标签
        projectFieldType: [], // 领域类别
        fieldChildrenId: [] // 领域类别子标签
      },
      // 需求机构信息
      demandInfo: {
        institutionName: "", // 需求机构名称
        demandCountry: "", // 国家
        demandProvince: "", // 省
        demandCity: "", // 市
        demandType: [], // 需求机构类型
        linkman: "", // 联系人
        companyLinkman: "", // 联系电话
        companyLinkEmail: "" //联系邮箱
      },
      supplierInfo: [], // 供应商信息
      supplierInfoTable: [], // 供应商表格信息
      acessoryInfo: [], // 附件信息
      acessoryInfoTable: [] // 附件表格信息
    };
  },

  computed: {
    projectDataType() {
      return Number(this.$route.query.projectDataType);
    },
    id() {
      return Number(this.$route.query.id);
    },
    demandType() {
      return Number(this.$route.query.demandType);
    }
  },

  created() {
    if (this.projectDataType) {
      this.searchBasicInfo();
    }

    setTimeout(() => {
      this.isShowExtra = true;
    }, 400);
  },

  methods: {
    handleClick(tab, event) {
      if (this.projectDataType) {
        switch (tab.name) {
          case "third":
            this.searchSupplierInfo();
            break;
          case "fourth":
            this.searchAcessoryInfo();
            break;
          default:
            break;
        }
      }
    },
    // 查询附件信息
    async searchAcessoryInfo() {
      const { data: res } = await getProjectFile({
        projectId: this.id
      });

      this.acessoryInfoTable = res;
    },
    // 查询供应商信息
    async searchSupplierInfo() {
      const { data: res } = await getProjectSupplier({
        projectId: this.id
      });

      this.supplierInfoTable = res;
    },
    // 查询需求机构信息
    async searchDemandInfo(params) {
      const { data: res } = await getProjectDemandById({
        id: params
      });

      this.demandInfo = res;

      this.demandInfo.demandType = this.formatString(
        this.demandInfo.demandType
      );
    },
    // 查询基本信息
    async searchBasicInfo() {
      const { data: res } = await getProjectDataById({ id: this.id });

      this.basicInfo = res;

      this.basicInfo.projectIndustryType = this.formatString(
        this.basicInfo.projectIndustryType
      );

      this.basicInfo.projectFieldType = this.formatString(
        this.basicInfo.projectFieldType
      );

      this.basicInfo.yearsTime = this.formatNumber(this.basicInfo.yearsTime);
      this.basicInfo.monthTime = this.formatNumber(this.basicInfo.monthTime);
      this.basicInfo.dayTime = this.formatNumber(this.basicInfo.dayTime);

      this.basicInfo.yearsCreatetime = this.formatNumber(
        this.basicInfo.yearsCreatetime
      );
      this.basicInfo.monthCreatetime = this.formatNumber(
        this.basicInfo.monthCreatetime
      );
      this.basicInfo.dayCreatetime = this.formatNumber(
        this.basicInfo.dayCreatetime
      );

      this.basicInfo.years = this.formatNumber(this.basicInfo.years);
      this.basicInfo.month = this.formatNumber(this.basicInfo.month);
      this.basicInfo.day = this.formatNumber(this.basicInfo.day);

      this.basicInfo.yearsDeadline = this.formatNumber(
        this.basicInfo.yearsDeadline
      );
      this.basicInfo.monthDeadline = this.formatNumber(
        this.basicInfo.monthDeadline
      );
      this.basicInfo.dayDeadline = this.formatNumber(
        this.basicInfo.dayDeadline
      );
      this.basicInfo.projectYears = this.formatNumber(
        this.basicInfo.projectYears
      );
      this.basicInfo.projectMonth = this.formatNumber(
        this.basicInfo.projectMonth
      );
      this.basicInfo.projectDay = this.formatNumber(this.basicInfo.projectDay);

      if (this.basicInfo.projectId) {
        this.searchDemandInfo(this.basicInfo.projectId);
      }
    },
    // 将字符串格式化为数字
    formatNumber(val) {
      if (val) {
        return Number(val);
      } else {
        return val;
      }
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    // 项目数据新增时新增供应商信息
    addSupplierInfo(val) {
      this.supplierInfo.push(JSON.parse(JSON.stringify(val)));

      this.supplierInfoTable.push(JSON.parse(JSON.stringify(val)));

      this.supplierInfoTable.forEach(item => {
        if (item.supplierType.length) {
          item.supplierType = item.supplierType.join(",");
        }
      });
    },
    // 项目数据新增时编辑供应商信息
    updateSupplierInfo(index, val) {
      this.$set(this.supplierInfo, index, val);

      this.$set(this.supplierInfoTable, index, val);

      this.supplierInfoTable.forEach(item => {
        if (item.supplierType.length) {
          item.supplierType = item.supplierType.join(",");
        }
      });
    },
    // 项目数据新增时删除供应商信息
    deleteSupplierInfo(index) {
      this.supplierInfo.splice(index, 1);

      this.supplierInfoTable.splice(index, 1);
    },
    // 项目数据新增时新增附件信息
    addAcessoryInfo(val) {
      this.acessoryInfo.push(JSON.parse(JSON.stringify(val)));

      this.acessoryInfoTable.push(JSON.parse(JSON.stringify(val)));
    },
    // 项目数据新增时编辑附件信息
    updateAcessoryInfo(index, val) {
      this.$set(this.acessoryInfo, index, val);

      this.$set(this.acessoryInfoTable, index, val);
    },
    // 项目数据新增时删除附件信息
    deleteAcessoryInfo(index) {
      this.acessoryInfo.splice(index, 1);

      this.acessoryInfoTable.splice(index, 1);
    },
    deleteMultiAcessoryInfo(val) {
      this.acessoryInfo = this.acessoryInfo.filter(
        item => !val.some(el => el.timestamp === item.timestamp)
      );
      this.acessoryInfoTable = this.acessoryInfoTable.filter(
        item => !val.some(el => el.timestamp === item.timestamp)
      );
    },
    saveItem() {
      // 基本信息表单验证
      const basicInfoForm = new Promise((resolve, reject) => {
        this.$refs.basicInfo.$refs.rulesBasicInfo.validate(valid => {
          if (valid) resolve();
        });
      });

      if (this.basicInfo.fieldChildrenId) {
        this.basicInfo.projectFieldType = this.basicInfo.projectFieldType.concat(
          this.basicInfo.fieldChildrenId
        );
      }
      if (this.basicInfo.industryChildrenId) {
        this.basicInfo.projectIndustryType = this.basicInfo.projectIndustryType.concat(
          this.basicInfo.industryChildrenId
        );
      }

      this.basicInfo.projectIndustryType = [
        ...new Set(this.basicInfo.projectIndustryType)
      ];
      this.basicInfo.projectFieldType = [
        ...new Set(this.basicInfo.projectFieldType)
      ];
      let res;
      // 基本信息编辑
      if (this.projectDataType) {
        Promise.all([basicInfoForm]).then(async () => {
          switch (this.activeName) {
            case "first":
              res = await updateProjectData(this.basicInfo);
              break;

            case "second":
              Object.assign(this.demandInfo, {
                projectId: this.basicInfo.id,
                projectDataIntegrity: this.basicInfo.projectDataIntegrity
              });
              res = await updateProjectDemand(this.demandInfo);
              break;

            case "third":
              this.$router.go(-1);
              break;

            case "fourth":
              this.$router.go(-1);
              break;

            default:
              break;
          }

          if (res.code === 200) {
            this.$message.success("编辑成功");

            if (this.demandType) {
              this.$router.go(-1);
            } else {
              this.$router.go(-1);
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        // 需求机构表单验证
        const demandInfoForm = new Promise((resolve, reject) => {
          this.$refs.demandInfo.$refs.rulesDemandInfo.validate(valid => {
            if (valid) resolve();
          });
        });
        Promise.all([basicInfoForm, demandInfoForm]).then(async () => {
          const params = {
            projectAddParam: this.basicInfo,
            projectSupplierAddParam: this.supplierInfo,
            projectDemandAddParam: this.demandInfo,
            projectFile: this.acessoryInfo
          };

          res = await addProjectData(params);

          if (res.code === 200) {
            this.$message.success("新增成功");

            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    cancelItem() {
      if (this.demandType) {
        this.$router.go(-1);
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style lang="less" scoped></style>
