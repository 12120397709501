<template>
  <div class="supplierInfo">
    <el-button
      type="primary"
      icon="jr-iconjr-icon-add"
      @click="dialogVisible=true;dialogType=0"
    >添加供应商</el-button>
    <el-table
      v-if="isShow"
      class="ly_table"
      :data="supplierInfoTable"
      :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
    >
      <el-table-column prop="supplierName" label="供应商名称" align="center" />
      <el-table-column label="所在地区" align="center">
        <template v-slot="{row}">
          {{row.supplierCountry?row.supplierCountry:''}}
          {{row.supplierProvince?'-'+row.supplierProvince:''}}
          {{row.supplierCity?'-'+row.supplierCity:''}}
        </template>
      </el-table-column>
      <el-table-column prop="supplierType" label="供应商类型" align="center" />
      <el-table-column prop="supplierLinkman" label="联系人" align="center" />
      <el-table-column prop="supplierPhone" label="联系电话" align="center" />
      <el-table-column prop="supplierEmail" label="联系邮箱" align="center" />
      <el-table-column label="是否合作" align="center">
        <template v-slot="{row}">{{row.tender?'是':'否'}}</template>
      </el-table-column>
      <el-table-column prop="bidding" label="是否接洽" align="center">
        <template v-slot="{row}">{{row.bidding?'是':'否'}}</template>
      </el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <button-table
            @buttonClick="editItem(scope.$index,scope.row)"
            content="编辑"
            icon="jr-iconjr-icon-edit"
          />
          <button-table
            @buttonClick="deleteItem(scope.$index,scope.row)"
            content="删除"
            icon="jr-iconjr-icon-delect"
          />
        </template>
      </el-table-column>
      <template slot="empty">
          <img
            src="@/assets/img/table.png"
            alt="没有数据"
            srcset=""
          />
        </template>
    </el-table>

    <el-dialog
      :title="dialogType ? '编辑供应商' : '添加供应商'"
      :visible.sync="dialogVisible"
      width="60%"
      @close="handleClose"
      center
    >
      <el-form
        ref="supplierInfoObj"
        :model="supplierInfoObj"
        :rules="supplierInfoRules"
        label-width="120px"
      >
        <el-form-item label="供应商名称：" prop="supplierName">
          <el-input v-model="supplierInfoObj.supplierName" placeholder="请输入供应商名称" />
        </el-form-item>
        <el-form-item label="所在地区：">
          <cascader-area
            ref="cascaderArea"
            :country="supplierInfoObj.supplierCountry"
            :province="supplierInfoObj.supplierProvince"
            :city="supplierInfoObj.supplierCity"
            @searchCountry="searchCountry"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
          />
        </el-form-item>
        <el-form-item label="供应商类型：">
          <el-checkbox-group v-model="supplierInfoObj.supplierType">
            <el-checkbox
              v-for="(item,index) in supplierTypeArray"
              :key="index+'only'"
              :label="item.label"
            ></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="联系人：">
          <el-input v-model="supplierInfoObj.supplierLinkman" placeholder="请输入联系人" />
        </el-form-item>
        <el-form-item label="联系电话：">
          <el-input v-model="supplierInfoObj.supplierPhone" placeholder="请输入联系电话" />
        </el-form-item>
        <el-form-item label="联系邮箱：">
          <el-input v-model="supplierInfoObj.supplierEmail" placeholder="请输入联系邮箱" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitForm">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CascaderArea from '@/components/CascaderArea.vue'
import ButtonTable from '@/components/ButtonTable.vue'
import {
  addProjectSupplier,
  updateProjectSupplier,
  deleteProjectSupplier,
} from '@/api/numberProject'
export default {
  name: 'SupplierInfo',

  props: {
    projectDataType: {
      type: Number,
    },
    supplierInfoTable: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
    },
  },

  components: {
    CascaderArea,
    ButtonTable,
  },

  data() {
    return {
      dialogType: 0, // 面板类型 0 添加 1 编辑
      dialogVisible: false,
      supplierInfoObj: {
        supplierName: '', // 供应商名称
        supplierCountry: '', // 国家
        supplierProvince: '', // 省
        supplierCity: '', // 市
        supplierType: [], // 供应商类型
        supplierLinkman: '', // 联系人
        supplierPhone: '', // 联系电话
        supplierEmail: '', // 联系邮箱
      },
      // 验证
      supplierInfoRules: {
        supplierName: [
          { required: true, message: '请输入供应商名称', trigger: 'blur' },
        ],
      },
      currentIndex: '',
      supplierTypeArray: [
        {
          label: '平台公司',
        },
        {
          label: '方案集成商',
        },
        {
          label: '应用提供商',
        },
        {
          label: '公司组织',
        },
      ],
      isShow: true,
    }
  },

  methods: {
    // 编辑
    editItem(index, row) {
      this.currentIndex = index

      Object.assign(this.supplierInfoObj, row)

      this.supplierInfoObj.supplierType = this.formatString(
        this.supplierInfoObj.supplierType
      )

      this.dialogType = 1

      this.dialogVisible = true
    },
    // 删除
    async deleteItem(index, row) {
      this.$confirm('确认删除吗 ?', '提示')
        .then(async () => {
          // 编辑
          if (this.projectDataType) {
            const res = await deleteProjectSupplier({ id: row.id })
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.$emit('searchSupplierInfo')
            }
          } else {
            this.$emit('deleteSupplierInfo', index)
          }
        })
        .catch(() => {})
    },
    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return []
      } else if (!val.length) {
        return val
      } else {
        return val.split(',')
      }
    },
    submitForm() {
      this.$refs.supplierInfoObj.validate(async (valid) => {
        if (valid) {
          let res
          // 项目数据编辑
          if (this.projectDataType) {
            // 供应商信息编辑
            if (this.dialogType) {
              // 编辑
              res = await updateProjectSupplier(this.supplierInfoObj)
            } else {
              // 新增
              res = await addProjectSupplier(
                Object.assign(this.supplierInfoObj, { projectId: this.id })
              )
            }
            if (res.code === 200) {
              this.$message.success(`${this.dialogType ? '编辑' : '新增'}成功`)

              this.$emit('searchSupplierInfo')
            }
          } else {
            // 项目数据新增
            // 供应商信息编辑
            if (this.dialogType) {
              this.$emit(
                'updateSupplierInfo',
                this.currentIndex,
                this.supplierInfoObj
              )
            } else {
              this.$emit('addSupplierInfo', this.supplierInfoObj)
            }
          }
        }
      })

      this.dialogVisible = false
    },
    handleClose() {
      this.dialogVisible = false

      this.supplierInfoObj = {
        supplierName: '', // 供应商名称
        supplierCountry: '', // 国家
        supplierProvince: '', // 省
        supplierCity: '', // 市
        supplierType: [], // 供应商类型
        supplierLinkman: '', // 联系人
        supplierPhone: '', // 联系电话
        supplierEmail: '', // 联系邮箱
      }

      this.$refs.cascaderArea.clear()
    },

    searchCountry(val) {
      this.supplierInfoObj.supplierCountry = val
    },
    searchProvince(val) {
      this.supplierInfoObj.supplierProvince = val
    },
    searchCity(val) {
      this.supplierInfoObj.supplierCity = val
    },
  },
}
</script>

<style>
</style>
