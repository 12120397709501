import request from '@/utils/request'

// 项目数据查询
export function getProjectList(data) {
  return request({
    url: '/system/project/getProjectList',
    method: 'post',
    data
  })
}

// 项目数据删除
export function deleteProjectById(data) {
  return request({
    url: '/system/project/deleteProjectById',
    method: 'post',
    data
  })
}

// 项目数据新增
export function addProjectData(data) {
  return request({
    url: '/system/project/save',
    method: 'post',
    data
  })
}

// 项目数据修改
export function updateProjectData(data) {
  return request({
    url: '/system/project/update',
    method: 'put',
    data
  })
}


// 项目数据查询单个
export function getProjectDataById(data) {
  return request({
    url: '/system/project/getProjectById',
    method: 'get',
    params: data
  })
}

// 项目数据导入
export function uploadImportProjectData(data) {
  return request({
    url: "/system/project/importProject",
    method: "post",
    data
  })
}

// 需求机构查询
export function getProjectDemand(data) {
  return request({
    url: '/system/projectDemand/listPage',
    method: 'post',
    data
  })
}

// 需求机构单个查询
export function getProjectDemandById(data) {
  return request({
    url: '/system/projectDemand/listById',
    method: 'get',
    params: data
  })
}

// 需求机构修改
export function updateProjectDemand(data) {
  return request({
    url: '/system/projectDemand/update',
    method: 'put',
    data
  })
}

// 供应商信息新增
export function addProjectSupplier(data) {
  return request({
    url: '/system/projectSupplier/add',
    method: 'post',
    data
  })
}

// 供应商信息编辑
export function updateProjectSupplier(data) {
  return request({
    url: '/system/projectSupplier/update',
    method: 'post',
    data
  })
}

// 供应商信息查询
export function getProjectSupplier(data) {
  return request({
    url: '/system/projectSupplier/listAll',
    method: 'get',
    params: data
  })
}

// 供应商信息删除
export function deleteProjectSupplier(data) {
  return request({
    url: '/system/projectSupplier/deleteById',
    method: 'delete',
    params: data
  })
}

// 附件信息新增
export function addProjectFile(data) {
  return request({
    url: '/system/projectFile/save',
    method: 'post',
    data
  })
}

// 附件信息编辑
export function updateProjectFile(data) {
  return request({
    url: '/system/projectFile/update',
    method: 'post',
    data
  })
}

// 附件信息查询
export function getProjectFile(data) {
  return request({
    url: '/system/projectFile/listAll',
    method: 'get',
    params: data
  })
}

// 附件信息删除
export function deleteProjectFile(data) {
  return request({
    url: '/system/projectFile/deleteById',
    method: 'post',
    data
  })
}

// 附件信息查询单个
export function getProjectFileById(data) {
  return request({
    url: '/system/projectFile/findById',
    method: 'get',
    params: data
  })
}

export function getProjectDemandList(data) {
  return request({
    url: '/system/projectDemand/listAll',
    method: 'post',
    data
  })
}

// 供给机构查询
export function getProjectSupplierMode(data) {
  return request({
    url: '/system/projectSupplier/getSupplist',
    method: 'post',
    data
  })
}

// 供给机构模块查询单个
export function getProjectSupplierModeById(data) {
  return request({
    url: '/system/projectSupplier/getproSupplistByid',
    method: 'post',
    data
  })
}

